.container {
  background-color: var(--card-color);
  padding-block: 120px;
  padding-inline: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  overflow: hidden;
}

.card {
  width: 324px;
  height: 72px;
  background: var(--white);
  border-radius: 16px;
  align-items: center;
  padding-left: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.flat {
  flex-direction: column;
  margin-top: 40px;
}

.circle {
  width: 48px;
  height: 48px;
  background: var(--card-color);
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  display: flex;
}

.img {
  height: 18.5px;
  width: 21.5px;
}

.title {
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: var(--main-color);
  margin-left: 16px;
}

.form {
  height: 430px;
  width: 736px;
  border-radius: 16px;
  background-color: var(--white);
  margin-left: 40px;
  padding: 40px;
}

.headLine {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 25px;
  line-height: 29px;
  color: var(--main-color);
}

.desc {
  color: #1c1c1c;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  margin-bottom: 40px;
}

.btnCard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 8px;
  width: 220px;
  height: 46px;
  background: var(--main-color);
  border-radius: 8px;
  cursor: pointer;
  border-width: 0px;
  margin-left: auto;
}

.btnTxt {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--white);
}

.btnIcon {
  width: 24px;
  height: 24px;
}

.inputRow {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.input {
  border-bottom-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-color: #dcdcdc;
  line-height: 2.5;
  background: transparent;
  outline: none;
  margin-bottom: 32px;
}

::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(39, 39, 39, 0.3);
}

.labelTxt {
  font-size: 12px;
  line-height: 18px;
  color: #272727;
}

.star {
  color: #f20404;
}

.largeInput {
  border-bottom-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-color: #dcdcdc;
  width: 100%;
  line-height: 2.5;
  background: transparent;
  outline: none;
  margin-bottom: 32px;
}

.col {
  flex-direction: column;
  display: flex;
  width: 45%;
}

@media only screen and (min-width: 1224px) {}

@media only screen and (min-width: 481px) and (max-width: 1224px) {
  .container {
    flex-direction: column;
    padding-inline: var(--ipad-padding--inline);
    padding-block: var(--ipad-padding--block);
  }

  .form {
    margin-left: 0px;
  }

  .card {
    width: 454px;
  }
}

@media (max-width: 480px) {
  .container {
    flex-direction: column;
    padding-inline: var(--mobile-padding--inline);
    padding-block: var(--mobile-padding--block);
  }

  .form {
    margin-left: 0px;
    width: 340px;
    padding-block: 32px;
    padding-inline: 16px;
    height: auto;
  }

  .inputRow {
    flex-direction: column;
  }

  .card {
    width: 340px;
  }

  .col {
    width: 100%;
  }
}