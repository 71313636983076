.container {
  display: flex;
  padding-inline: var(--main-padding);
  background-color: var(--card-color);
  width: 100%;
  flex-direction: row;
  position: relative;
  margin-top: 82px;
  height: calc(100vh - 82px);
  /* height: 100%; */
  margin-bottom: 1px;
  overflow: hidden;
}

.displayFlex {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.row {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  animation: slide-top-home 1s .4s forwards;
  opacity: 0;
  /* background-color: red; */
}

.title {
  font-family: 'Medium';
  font-size: 48px;
  line-height: 57px;
  color: var(--main-color);
  animation: slide-top-home 1s forwards;
  opacity: 0;

}

.subTitle {
  font-family: 'regular';
  font-size: 16px;
  color: var(--paragraph-color);
  margin-top: 16px;
  animation: slide-top-home 1s .2s forwards;
  opacity: 0;

}

span {
  color: var(--secondary-color);

}

.storesImageSize {
  width: 120px;
  height: 40px;
  margin-top: 32px;
  margin-inline-end: 16px;
  cursor: pointer;
}

.image {
  /* width: 400px; */
  border-radius: 16px;
  box-shadow: 15px -15px var(--secondary-color);
  animation: slide-left 1.4s 0s forwards;
  height: 80%;
  margin-inline-end: 15px;
  margin-top: 15px;


}

.arrow {
  width: 90px;
  position: absolute;
  bottom: 30%;
  left: calc(0%);
  animation: fading 3s forwards;
  transition-delay: 1;

}

.message1 {
  position: absolute;
  left: 10%;
  background: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid #E8E8E8;
  box-shadow: 0px 4px 4px rgba(225, 225, 225, 0.25);
  border-radius: 8px;
  padding: 7px 20px;
  color: var(--main-color);
  animation: slide-top1 1s .2s forwards;
  font-size: medium;
  bottom: 0%;
  opacity: 0;


}

.message2 {
  position: absolute;
  left: 15%;
  background: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid #E8E8E8;
  box-shadow: 0px 4px 4px rgba(225, 225, 225, 0.25);
  border-radius: 8px;
  padding: 7px 20px;
  color: var(--main-color);
  animation: slide-top2 1s .5s forwards;
  font-size: medium;
  transition-duration: 1s;
  bottom: 0%;
  opacity: 0;
}



@keyframes slide-left {
  0% {
    transform: translateX(30vw);
    opacity: 0;

  }

  100% {
    transform: translateX(0);
    opacity: 1;

  }
}


@keyframes slide-top1 {


  from {
    bottom: 0%;
    opacity: 0;
  }

  to {
    bottom: 22%;
    opacity: 1;
  }
}

@keyframes slide-top2 {
  from {
    bottom: 0%;
    opacity: 0;
  }

  to {
    bottom: 14%;
    opacity: 1;
  }
}

@keyframes slide-top-home {
  0% {
    transform: translateY(50vh);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fading {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;

  }
}




@media only screen and (min-width: 481px) and (max-width:1224px) {
  .container {
    height: auto;
    /* flex-direction: column-reverse; */
    padding-block: 8vh;
    /* padding-block: 120vw; */
  }

  .image {
    width: 70%;
    height: auto;
  }

  .title {
    font-size: 40px;
    line-height: 57px;
  }


  .arrow {
    width: 70px;
    bottom: 38%;
  }

  .message1 {
    left: 7%;
    font-size: small;

  }

  .message2 {
    left: 14%;
    font-size: small;
  }

  @keyframes slide-top1 {
    from {
      bottom: 0%;
      opacity: 0;
    }

    to {
      bottom: 30%;
      opacity: 1;
    }
  }

  @keyframes slide-top2 {
    from {
      bottom: 0%;
      opacity: 0;
    }

    to {
      bottom: 20%;
      opacity: 1;
    }
  }


}



@media (max-width: 480px) {
  .container {
    margin-top: 66px;
    height: auto;
    flex-direction: column-reverse;
    padding-block: 8vh;
    /* padding-block: 120vw; */
  }

  .image {
    width: 70%;
    height: auto;
  }

  .arrow {
    width: 70px;
    bottom: 38%;
  }

  .message1 {
    left: 5%;
  }

  .message2 {
    left: 8%;
  }

  @keyframes slide-top1 {
    from {
      bottom: 0%;
      opacity: 0;
    }

    to {
      bottom: 20%;
      opacity: 1;
    }
  }

  @keyframes slide-top2 {
    from {
      bottom: 0%;
      opacity: 0;
    }

    to {
      bottom: 5%;
      opacity: 1;
    }
  }

  .title {
    font-size: 30px;
    line-height: 41px;
    margin-top: 30px;
  }
}